/* .consultation-card {
    width: 100%;
    max-width: 400px; 
    margin: 0 auto; 
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .consultation-card p {
    margin: 0; 
  }
  
  .book-now-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff; 
    color: #ffffff; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .book-now-button:hover {
    background-color: #0056b3; 
  }
   */

   .card {
    /* background-color: #f8f9fa; */
    background-color: #3e409a;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 1400px; /* Adjust as needed for an even wider card */
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 200px; /* Adjust the height as needed */
   }
  
   .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    height: 100%; /* Make the content container take full height */
   }
   
   .text-container {
     flex-grow: 1;
     margin-right: 20px; /* Adjust the spacing between text and button */
   } .custom-btn{
    /* style={{color:"#3E409A", border:"2px solid #3E409A"}} */
    color:#3E409A ;
    border: 2px solid #3E409A ;

  }
   .quick-btn{
    color: white;
    border: 2px solid white; /* Adjust the border width */
  }
 
  h2{
    color:white;
  }
 
   .book-now-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top:-15px;
   }
   
  
   
   /* Responsive Media Query */
   @media screen and (max-width: 600px) {
     .card {
       padding: 10px;
     }
     .card-content {
       flex-direction: column;
       align-items: flex-start;
     }
     .text-container h2{
      font-size: 22px;
     }
     .custom-btn {
      padding: 7px 15px;
     }
   }
   
   .quick-btn:hover {
    color: white;
}